import React from 'react';
import { Link, PlatformTab, PlatformTabEmptyState } from 'docComponents';

const AndroidTab = () => {
  return (
    <PlatformTab>
      <PlatformTabEmptyState platform="android" pageType="guidelines">
        Follow the{' '}
        <Link href="https://material.io/guidelines/">Material Guidelines</Link>{' '}
        for individual interface elements.
      </PlatformTabEmptyState>
    </PlatformTab>
  );
};

export default AndroidTab;
