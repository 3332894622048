import React from 'react';
import { Link, PlatformTab, PlatformTabEmptyState } from 'docComponents';

const AppleTab = () => {
  return (
    <PlatformTab>
      <PlatformTabEmptyState platform="apple" pageType="guidelines">
        Follow the{' '}
        <Link href="https://developer.apple.com/ios/human-interface-guidelines/overview/">
          HIG
        </Link>{' '}
        for individual interface elements.
      </PlatformTabEmptyState>
    </PlatformTab>
  );
};

export default AppleTab;
